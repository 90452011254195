<template>
     <div class=" industry body">
          <div class="container headcarousel">
               <div>
                    <div class="columns reverse-columns">
                         <div class="column ">
                              <div class="image" data-aos="fade-right" data-aos-duration="1000">
                                   <!-- <img :src="require(`@/assets/images/industry.png`)" alt="" /> -->

                                   <div
                                        class="square img"
                                        :style="{ 'background-image': `url(${require('@/assets/images/industries/mall.jpg')})` }"
                                   ></div>
                              </div>
                         </div>
                         <div class="column">
                              <div class="text" data-aos="fade-left" data-aos-duration="1000">
                                   <h1>Shopping <span class="uppertext">04</span></h1>
                                   <h1 class="pt-2">Mall IT & Infra</h1>

                                   <div class="mt-30">
                                        <p>
                                             Highly integrated systems design that combines security, utilities and services delivered with
                                             international standards
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div class="projects">
               <div class="container">
                    <div class="  section-title">Healthcare Projects</div>

                    <div class="columns" style="margin-bottom:50px;">
                         <div class="column">
                              <div class="section-header" style="margin-bottom:0;">
                                   Built to encompass the scope of your Infrastructural extensions.
                              </div>
                         </div>

                         <div class=" hoopernavigation">
                              <button class="prev" @click="hooperPrev()" :class="{ visible: hooper.isPrevBtnVisible }">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                                        <g id="Group_415" data-name="Group 415" transform="translate(1115.74 145.803) rotate(180)">
                                             <line
                                                  id="Line_40"
                                                  data-name="Line 40"
                                                  x2="42"
                                                  transform="translate(1073 136)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                             <path
                                                  id="Path_21"
                                                  data-name="Path 21"
                                                  d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                  transform="translate(2)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                        </g>
                                   </svg>
                              </button>
                              <button class="next" @click="hooperNext()" :class="{ visible: hooper.isNextBtnVisible }">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                                        <g id="Group_415" data-name="Group 415" transform="translate(-1073 -126.299)">
                                             <line
                                                  id="Line_40"
                                                  data-name="Line 40"
                                                  x2="42"
                                                  transform="translate(1073 136)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                             <path
                                                  id="Path_21"
                                                  data-name="Path 21"
                                                  d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                  transform="translate(2)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                        </g>
                                   </svg>
                              </button>
                         </div>
                    </div>
               </div>
               <div class="  overflow-carousel-container">
                    <div class="overflow-carousel">
                         <hooper :settings="hooper_settings" class="slider" style="" ref="hooper" @slide="hooperSlide">
                              <slide v-for="(item, index) in projects" :key="index" class="">
                                   <div class="slide-container">
                                        <div class="image-container">
                                             <!-- <img :src="require(`@/assets/images/${item.image}`)" /> -->

                                             <div
                                                  class="rect img"
                                                  v-if="item.image"
                                                  :style="{ 'background-image': `url(${require('@/assets/images/projects/' + item.image)})` }"
                                             ></div>

                                             <div
                                                  class="rect img"
                                                  v-else
                                                  :style="{ 'background-image': `url(${require('@/assets/images/placeholder.png')})` }"
                                             ></div>
                                        </div>
                                        <div class="mt-30 line-left  " style="padding-left:20px;min-height:91px;">
                                             <div class="date">{{ item.location }}</div>
                                             <p class="mt-10 large " v-html="item.name"></p>
                                        </div>
                                   </div>
                              </slide>
                         </hooper>
                    </div>
               </div>
          </div>

          <div class="content">
               <div class="container">
                    <div class="  section-title">Healthcare Services</div>
                    <div class="  section-header">Designed to upgrade Brand experience.</div>

                    <div class="columns">
                         <div class="column ">
                              <div class="line-top pt-6 mt-6 ">
                                   <!-- <div class="section-title ">
                                        Benefits of ITeS System
                                   </div> -->
                                   <p>
                                        IoT (Internet of Things) applications have the potential to transform the retail industry with more benefits.
                                        These are some of the ways in which IoT is being used in retail and how it is transforming the industry.
                                   </p>
                                   <p>
                                        Customer behavior<br />
                                        Footfalls<br />
                                        Smart stores<br />
                                        Smart Racks <br />Digital signage <br />Self-checkout<br />
                                        kiosks
                                   </p>
                                   <p>
                                        Shopping mall designs have to be efficient as they consume a lot of power due to the increased open space and
                                        people. Implementation of an IoT based sensor and AI (Artificial Intelligence) the system can identify the
                                        crowd clusters and distribution of people, this is important to regulate temperature.
                                   </p>

                                   <div class="line-top   pt-6 mt-6 ">
                                        <div class="section-title ">
                                             Wifi and DAS
                                        </div>
                                        <p>
                                             The Wifi and DAS (Distributed Antenna Systems) must be designed and implemented based on the expected
                                             maximum foot falls. The internet availability must be ensured at each and every corner of the shopping
                                             malls.
                                        </p>
                                        CTL uses advanced software tools to generate the Wifi and cellular signal heat maps and strategise materials
                                        accordingly.
                                        <p></p>
                                   </div>

                                   <div class="line-top  pt-6 mt-6  ">
                                        <div class="section-title ">
                                             Theft management
                                        </div>
                                        <p>
                                             Theft management and people's safety is another area which needs to be addressed and planned at the
                                             construction stage. Entry control systems like, under vehicle Scanning systems, ANPR, boom berries and
                                             bollards must be implemented at the gate level .
                                        </p>
                                   </div>
                              </div>
                         </div>
                         <div class="column ">
                              <div class="line-top  pt-6 mt-6 ">
                                   <div class="section-title ">
                                        Specific Services
                                   </div>
                                   <p>
                                        With our past experience, we can provide the following solutions based on the mall size and business plan.
                                   </p>

                                   <p>
                                        Wired and Wireless Data <br />
                                        Switching and Routing <br />
                                        Firewalls and Servers <br />
                                        IP Telephony <br />
                                        IP Surveillance <br />
                                        Access control systems <br />
                                        Building Management Systems <br />
                                        Data Centre and Command control solutions <br />
                                        IoT and Store Management Systems <br />
                                        Audio and Video <br />
                                        Digital Signage <br />
                                        IP TV Solutions<br />
                                        Addressable smoke detection systems <br />
                                        Boom Barriers , Bollards, ANPR, UVSS <br />
                                        Parking Management systems <br />
                                   </p>
                              </div>

                              <div class="line-top  pt-6 mt-6  ">
                                   <div class="section-title ">
                                        Theft management
                                   </div>
                                   <p>
                                        Parking management is one of the major challenges in the shopping malls. A proper parking management system
                                        must be designed and installed to avoid long waiting of customers.
                                   </p>
                              </div>

                              <div class="line-top  pt-6 mt-6  ">
                                   <div class="section-title ">
                                        IP Surveillance
                                   </div>
                                   <p>
                                        IP Surveillance along with AI (Artificial Intelligence) can help to manage and control any unlawful activity
                                        inside the property.
                                   </p>
                              </div>

                              <div class="line-top  pt-6 mt-6  ">
                                   <p>
                                        Each store will have their own connectivity demands , some of them may require multiple connectivity to
                                        maintain the network redundancy. This basic network infra must be made available from day one to meet user
                                        expectations.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import { Hooper, Slide } from "hooper";
     import "hooper/dist/hooper.css";

     export default {
          data() {
               return {
                    hooper: {
                         slideNo: 0,
                         isPrevBtnVisible: false,
                         isNextBtnVisible: true,
                    },
                    hooper_settings: {
                         wheelControl: false,
                         centerMode: false,
                         trimWhiteSpace: true,
                         breakpoints: {
                              800: {
                                   itemsToShow: 1,
                                   itemsToSlide: 1,
                              },
                              1000: {
                                   itemsToShow: 3.6,
                                   itemsToSlide: 1,
                              },
                         },
                    },

                    sliderOptions: {},
                    selected: 0,

                    projects: [
                         {
                              name: "Lulu International Shopping Mall Pvt Ltd",
                              location: "Kerala",
                              text: `ICT Solution<br>
     Addressable Smoke detection systems<br>
     CCTV                      <br>
     IP Surveillance           <br>
     ITeS  Solution
     `,
                              image: "shopping_mall_IT_infra/Lulu_mall/1.jpeg",
                         },

                         {
                              name: "Mall of Joy",
                              location: "Kerala",
                              text: `

                                             CCTV <br>
     Access control Systems <br>
     Public address systems<br>
     ITeS  Solution  <br>
     Structured Cabling                                                                    `,
                              image: "shopping_mall_IT_infra/mall_of_joy/1.jpg",
                         },

                         {
                              name: "Y-MALL ",
                              location: "Kerala",
                              text: `ICT Solution <br>
     Electronic Security <br>
     Entry Control Systems  <br>
     IP Surveillance <br>
     ITeS  Solution    <br>                                                                 `,
                              image: "shopping_mall_IT_infra/Y-mall/1.jpg",
                         },
                         {
                              name: "Mantle Solutions",
                              location: "Kerala",
                              text: `ICT Solution <br>
     Electronic Security <br>
     Entry Control Systems  <br>
     IP Surveillance <br>
     ITeS  Solution    <br>                                                                 `,
                              image: "shopping_mall_IT_infra/Mantle_solution/1.jpg",
                         },

                         {
                              name: "Nippon infra",
                              location: "Kerala",
                              text: `GPON  Solution <br>
     ITeS  Solution  <br>
     Structured Cabling`,
                              image: "shopping_mall_IT_infra/Nippon_infra/1.jpg",
                         },
                         {
                              name: "accenture",
                              location: "Mumbai",
                              text: `ICT Solution  <br>
     Electronic Security<br>
     IT Surveillance  <br>
     ITeS  Solution<br>
     Audio Video<br>
     Structured Cabling `,
                              image: "shopping_mall_IT_infra/accenture/1.jpg",
                         },
                         {
                              name: "Mariapps (Consulting)",
                              location: "Kerala",
                              text: `ICT Solution  <br>
     Electronic Security<br>
     IT Surveillance  <br>
     ITeS  Solution<br>
     Audio Video<br>
     Structured Cabling `,
                              image: "shopping_mall_IT_infra/Mariapps/1.jpg",
                         },
                    ],
               };
          },
          components: {
               Hooper,
               Slide,
          },
          methods: {
               hooperSlide(data) {
                    this.hooper.slideNo = data.currentSlide;
                    console.log(this.hooper.slideNo);
                    if (this.hooper.slideNo > 0) {
                         this.hooper.isPrevBtnVisible = true;
                    } else {
                         this.hooper.isPrevBtnVisible = false;
                    }

                    if (window.innerWidth < 1000) {
                         if (this.hooper.slideNo == this.projects.length - 1) {
                              this.hooper.isNextBtnVisible = false;
                         } else {
                              this.hooper.isNextBtnVisible = true;
                         }
                    } else {
                         if (this.hooper.slideNo > 3.3) {
                              this.hooper.isNextBtnVisible = false;
                         } else {
                              this.hooper.isNextBtnVisible = true;
                         }
                    }
               },
               hooperPrev() {
                    this.$refs.hooper.slidePrev();
               },
               hooperNext() {
                    this.$refs.hooper.slideNext();
               },
          },

          mounted() {},
     };
</script>

<style lang="scss" scoped>
     .section-header {
          margin-bottom: 10px;
     }
</style>
